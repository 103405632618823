import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';

const app = initializeApp({
  apiKey: "AIzaSyBGKpf5p71srn0zvsakdxGPo79VU2aJyhk",
  authDomain: "mqtt-test-project-76f9c.firebaseapp.com",
  projectId: "mqtt-test-project-76f9c",
  storageBucket: "mqtt-test-project-76f9c.appspot.com",
  messagingSenderId: "191738994731",
  appId: "1:191738994731:web:7829970c0c687ae8430a6e"
});

export const db = getFirestore(app);
export const auth = getAuth();
