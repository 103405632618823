import { getAuth, sendEmailVerification } from "firebase/auth";
import Button from "../components/Button";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { useEffect, useState } from "react";

const VerifyEmailPage = () => {
  const navigate = useNavigate();
  const [sent, setSent] = useState(false);

  useEffect(() => {
    const auth = getAuth();
    if (auth.currentUser.emailVerified) {
      navigate('/');
      return;
    }
  }, []);

  async function sendEmail() {
    const auth = getAuth();

    if (auth.currentUser.emailVerified) {
      navigate('/');
      return;
    }

    if (sent) {
      return;
    }

    await sendEmailVerification(auth.currentUser);

    toast.success("E-mail sent.");

    setSent(true);
  }

  return (
    <div className="min-h-screen bg-black">
      <ToastContainer position='bottom-center' theme='colored' />

      <div className="min-h-screen flex flex-col justify-center items-center">
        <img src="/logo.png" className="w-[150px] h-[150px]" />

        <h1 className="text-white text-3xl mt-8 font-bold">Verify E-mail</h1>

        <p className="text-white mt-4 text-lg px-4">You should verify your e-mail. After your register we sent you an e-mail for this. If you can't access it please click button below.</p>

        {sent ?
          <></>
        :<Button onClick={sendEmail} className="mt-4">Re-send e-mail</Button>}
      </div>

    </div>
  );
};

export default VerifyEmailPage;
