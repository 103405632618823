import { useState, useEffect, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
// import DashboardLayout from '../components/DashboardLayout';
import { deleteDoc, doc } from 'firebase/firestore';
import { db } from '../firebase';
import projectService from '../services/project';
import { AuthContext } from '../context/AuthContext';
import { ProjectContext } from '../context/ProjectContext';

// v2
import Dashboard from '../layouts/Dashboard';
import H1 from '../components/h1';
import Button from '../components/Button';
import DeleteProjectPopup from '../components/popup/DeleteProjectPopup';
import { toast } from 'react-toastify';
import { getAuth } from 'firebase/auth';

const ProjectsPage = () => {
  const [deletePopup, setDeletePopup] = useState(false);
  const [selectedProject, setSelectedProject] = useState(null);
  const [projects, setProjects] = useState([]);
  const {currentUser} = useContext(AuthContext);
  const currentProject = useContext(ProjectContext);
  const navigate = useNavigate();

  const fetchData = async () => {
    let res = await projectService.getMyProjects(currentUser);
    if (res) {
      setProjects(res);
    }
  };

  useEffect(() => {
    // check is user verified
    const auth = getAuth();
    const user = auth.currentUser;
    if (user !== null) {
      const emailVerified =  user.emailVerified;
      if (!emailVerified) {
        navigate('/verify-email');
      }
    }

    currentProject.dispatch({type: "RESET"});
    fetchData();
  }, []);

  return (
    <Dashboard>
      <div className="p-[20px] max-w-[600px] w-full">
        <H1>Projects ({projects ? projects.length : 0}/2)</H1>
        {projects && projects.length >= 2 ?
        <p className="text-white">
          You reached your project limit. To create new one please remove some of your projects.
        </p>
        : <></>}

        {currentUser.role == "admin" && (projects ? projects.length < 2 : true) ?
        <Link to="/projects/create" className="block w-fit mt-6">
          <Button rounded={false}>New project</Button>
        </Link>
        : <></>}

        <table className="mt-[30px] w-full">
          <thead>
            <tr>
              <th><div className="text-left text-lg text-bold text-white">Name</div></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {projects.map((project, index) =>
            <tr key={index}>
              <td className="py-3">
                <Link to={`projects/${project.id}`}>
                  <div className="text-orange">{project.name}</div>
                </Link>
              </td>
              <td className="py-3">
                {currentUser.role == "admin" ?
                <div className="flex gap-2">
                  <Link to={`/projects/${project.id}/edit`}>
                    <Button rounded={false} type="secondary"><img className="w-[20px] h-[20px]" src="/icons/pen.svg" /></Button>
                  </Link>
                  <Button rounded={false} onClick={() => {setSelectedProject(project); setDeletePopup(true)}} type="danger"><img src="/icons/thrash.svg" /></Button>
                </div>
                : <></>}
              </td>
            </tr>
            )}
          </tbody>
        </table>
        {projects.length == 0 ?
          <div className="text-2xl text-white">There are no projects available...</div>
        : <></>}
        {deletePopup && selectedProject != null ?
          <DeleteProjectPopup project={selectedProject} onCancel={() => setDeletePopup(false)} onDelete={() => {setDeletePopup(false); fetchData();}} />
        : <></>}
      </div>
    </Dashboard>
  );
}

export default ProjectsPage;
