import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { db } from '../firebase';
import { setDoc, doc, serverTimestamp, updateDoc } from 'firebase/firestore';
import slugify from 'react-slugify';
import Dashboard from '../layouts/Dashboard';
import FormRow from '../components/form/FormRow';
import Label from '../components/Label';
import Input from '../components/Input';
import H1 from '../components/h1';
import Button from '../components/Button';
import Switch from '../components/Switch';
import { toast } from 'react-toastify';
import { AuthContext } from '../context/AuthContext';
import projectService from '../services/project';

const ProjectsCreatePage = () => {
  const {currentUser, dispatch} = useContext(AuthContext);
  const navigate = useNavigate();
  const [projects, setProjects] = useState([]);

  const fetchData = async () => {
    let res = await projectService.getMyProjects(currentUser);
    if (res) {
      setProjects(res);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);
  /*
  const [data, setData] = useState({
    name: "",
    protocol: "wss",
    address: "broker.emqx.io",
    port: "8084",
    path: "/mqtt",
    identifier: "",
    adminMessage: "",
    autoConnect: false,
  });
  */
  const [data, setData] = useState({
    name: "",
    protocol: "wss",
    address: "",
    port: "8084",
    path: "/mqtt",
    identifier: "",
    adminMessage: "",
    autoConnect: false,
  });

  function randString(length) {
      let result = '';
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      const charactersLength = characters.length;
      let counter = 0;
      while (counter < length) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
        counter += 1;
      }
      return result;
  }

  async function handleSave(e) {
    e.preventDefault();

    let project = {
      name: data.name,
      broker: {
        protocol: data.protocol,
        address: data.address,
        port: data.port,
        path: data.path,
      },
      brokerFullUrl: `${data.protocol}://${data.address}:${data.port}${data.path}`,
      identifier: data.identifier,
      adminMessage: data.adminMessage,
      autoConnect: data.autoConnect,
      layout: [],
      timeStamp: serverTimestamp()
    };

    try {
      const projectId = slugify(data.name) + "-" + randString(20);
      await setDoc(doc(db, "projects", projectId), project);

      await updateDoc(doc(db, "users", currentUser.uid), {
        projects: [...currentUser.projects, projectId]
      });

      let tempUser = {...currentUser, projects: [...currentUser.projects, projectId]};
      dispatch({type: "LOGIN", payload: tempUser});
      
      toast.success('Project created');
      navigate("/");
    } catch (err) {
      toast.error(err);
      console.error(err);
    }
  }

  return (
    <Dashboard>
      <div className="p-[20px] max-w-[600px] w-full">
        <H1>New Project</H1>

        <form className="my-8 flex flex-col gap-4">

          <FormRow>
            <Label>Name</Label>
            <Input type="text" defaultValue={data.name} onChange={(e) => setData({...data, name: e.target.value})} />
          </FormRow>

          <FormRow>
            <Label>Broker Protocol</Label>
            <Input type="text" defaultValue={data.protocol} onChange={(e) => setData({...data, protocol: e.target.value})} />
          </FormRow>

          <FormRow>
            <Label>Broker Address</Label>
            <Input type="text" defaultValue={data.address} onChange={(e) => setData({...data, address: e.target.value})} />
          </FormRow>

          <FormRow>
            <Label>Broker Port</Label>
            <Input type="text" defaultValue={data.port} onChange={(e) => setData({...data, port: e.target.value})} />
          </FormRow>

          <FormRow>
            <Label>Broker Path</Label>
            <Input type="text" defaultValue={data.path} onChange={(e) => setData({...data, path: e.target.value})} />
          </FormRow>

          <FormRow>
            <Label>Unique Identifier</Label>
            <Input type="text" defaultValue={data.identifier} onChange={(e) => setData({...data, identifier: e.target.value})} />
          </FormRow>

          <FormRow>
            <Label>Admin Message</Label>
            <Input type="text" defaultValue={data.adminMessage} onChange={(e) => setData({...data, adminMessage: e.target.value})} />
          </FormRow>

          <FormRow>
            <Label>Auto Connect ({data.autoConnect ? "Enabled" : "Disabled"})</Label>
            <Switch defaultValue={data.autoConnect} onClick={(e) => setData({...data, autoConnect: e})} />
          </FormRow>

          {projects && projects.length >= 2 ?
            <div className="text-white">You reached your project limit. To create new one please remove some of your projects.</div>
          :<Button onClick={handleSave}>Save</Button>}

        </form>
      </div>
    </Dashboard>
  );
}

export default ProjectsCreatePage;
