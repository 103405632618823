import { useContext } from 'react';
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate
} from 'react-router-dom';
import { AuthContext } from './context/AuthContext';
import HomePage from './pages/HomePage';
import LoginPage from './pages/LoginPage';
import UsersPage from './pages/UsersPage';
import UsersCreatePage from './pages/UsersCreatePage';
import UsersEditPage from './pages/UsersEditPage';
import SettingsPage from './pages/SettingsPage';
import ProjectsPage from './pages/ProjectsPage';
import ProjectsCreatePage from './pages/ProjectsCreatePage';
import ProjectsEditPage from './pages/ProjectsEditPage';
import LogsPage from './pages/LogsPage';
import PasswordResetPage from './pages/PasswordResetPage';
import "./index.css";
import RegisterPage from './pages/RegisterPage';
import NotFound from './pages/NotFound';
import VerifyEmailPage from './pages/VerifyEmailPage';

function App() {
  const {currentUser} = useContext(AuthContext);

  function RequireAuth({ children, type = null }) {
    if (currentUser && type) {
      if (currentUser.role === "user" && type === "admin") {
        return <Navigate to="/" />;
      } else {
        return children;
      }
    }

    return currentUser ? children : <Navigate to="/login" />;
  }

  function RequireNonAuth({ children }) {
    return !currentUser ? children : <Navigate to="/" />;
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route path="*" element={<NotFound />} />
        <Route path="/verify-email" element={<RequireAuth><VerifyEmailPage /></RequireAuth>} />
        <Route path="/login" element={<RequireNonAuth><LoginPage /></RequireNonAuth>} />
        <Route path="/register" element={<RequireNonAuth><RegisterPage /></RequireNonAuth>} />
        <Route path="/password-reset" element={<RequireNonAuth><PasswordResetPage/></RequireNonAuth>} />
        {/*
        <Route path="/users" element={<RequireAuth type="admin"><UsersPage /></RequireAuth>} />
        <Route path="/users/create" element={<RequireAuth type="admin"><UsersCreatePage /></RequireAuth>} />
        <Route path="/users/:id/edit" element={<RequireAuth type="admin"><UsersEditPage /></RequireAuth>} />
        */}
        <Route path="/settings" element={<RequireAuth><SettingsPage /></RequireAuth>} />
        <Route path="/projects/create" element={<RequireAuth type="admin"><ProjectsCreatePage /></RequireAuth>} />
        <Route path="/projects/:id/logs" element={<RequireAuth><LogsPage /></RequireAuth>} />
        <Route path="/projects/:id/edit" element={<RequireAuth type="admin"><ProjectsEditPage /></RequireAuth>} />
        <Route path="/projects/:id" element={<RequireAuth><HomePage /></RequireAuth>} />
        <Route path="/" element={<RequireAuth><ProjectsPage /></RequireAuth>} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
