const NotFound = () => {
  return (
    <div className="min-h-screen bg-black">

      <div className="min-h-screen flex flex-col justify-center items-center">
        <img src="/logo.png" className="w-[150px] h-[150px]" />

        <h1 className="text-white text-3xl mt-8 font-bold">404 Not Found</h1>
      </div>

    </div>
  );
};

export default NotFound;
